import React from 'react';
import './App.css';
import Homepage from "./pages/homepage/Homepage";
import {Route, Routes} from "react-router-dom";
import PageToDelete from "./pages/pageToDelete/PageToDelete";
import AnotherPageToDelete from "./pages/pageToDelete/AnotherPageToDelete";

function App() {
  return (
    <div className="App">
      <h1>Loooooooty</h1>
        <Routes>
            <Route path="/" element={<Homepage/>}/>
            <Route path="/tmp" element={<PageToDelete/>}/>
            <Route path="*"  element={<AnotherPageToDelete/>}/>
        </Routes>
    </div>
  );
}

export default App;
