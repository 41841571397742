import React from "react";

const AnotherPageToDelete = () => {

    return (
        <div>
            <p>404 error</p>
        </div>
    );
}

export default AnotherPageToDelete