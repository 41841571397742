import {useEffect, useState} from "react";
import axios from '../api/axios';

const useDataFetch = (endpoint: string) => {
    const [data, setData] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(endpoint);
            setData(response.data)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [endpoint]);

    return {data, error, isLoading, fetchData};

}

export default useDataFetch;