import React from "react";

const PageToDelete = () => {

    return (
        <div>
            <p>Page To delete</p>
        </div>
    );
}

export default PageToDelete