import React from "react";
import useDataFetch from "../../hooks/useDataFetch";

const Homepage = () => {

    const {data} = useDataFetch(
        `/dummy/hello`
    );

    return (
        <div>
            {data}
        </div>
    );
}

export default Homepage